.search-container {
  margin-top: 2rem;
}

.search-box {
  height: 3rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.youtube-logo {
  width: 4rem;
  margin-right: 0.3rem;
}

.iframe-box {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

::placeholder {
  font-size: 0.8rem;
  text-align: center;
}

.logo-row {
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.copy-link {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.copy-txt {
  margin-right: 0.3rem;
}

.catch-phrase {
  align-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #ffce00;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  margin-top: 3rem;
  margin-bottom: 1rem;
}
